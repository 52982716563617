/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import config from '../config'
import router from '../routes'

const api = axios.create({
    baseURL: config.ServerEndPoint + '/api/organisation'
  })

export default {
    getAllOrganisation() {
        return api.get('getAllOrganisations')
    },
    addNewOrganisation(organisation){
        return api.post('addNewOrganisation', organisation)
    }
}