<template>
  <div class="container-fluid">
    <div class="mt-4 col-md-5 text-left">
      <h3>List of Organisations</h3>
      <hr class="mt-n2">
    </div>
    <div class="col-md-12 mt-1">
      <OTable
            class="mt-2"
            :fields="tablefields"
            :allitems="organisations"
            :pages="pages"
            :defaultsort="defaultsort"
            :showOptionsButton="false"
            hide-goto-end-buttons
            pagination-class="justify-content-end"
            @pageChanged="pageChanged"
            :select-mode="false"
            :default-text="defaultText"
            bordered
            small
          >
      </OTable>
    </div>
     <div class="col-md-6 mt-3 text-left">
        <OForm
            :readonly="readonly"
            :fields="formfields"
            v-model="details"
            :okButtonText="okButtonText"
            :inline="inlineform"
            @ok="onSubmit"
          />
     </div>
    <!--<OAccordion class="flex col-md-12 mt-3">
      <OAccordionCard title="List of Organisations">
        <OTable
            class="mt-2"
            :fields="tablefields"
            :allitems="organisations"
            :pages="pages"
            :defaultsort="defaultsort"
            :showOptionsButton="false"
            hide-goto-end-buttons
            pagination-class="justify-content-end"
            @pageChanged="pageChanged"
            :select-mode="false"
            :default-text="defaultText"
            bordered
            small
          >
        </OTable>
      </OAccordionCard>
      <OAccordionCard title="Add a new Organisation">
          <OForm
            :readonly="readonly"
            :fields="formfields"
            v-model="details"
            :okButtonText="okButtonText"
            :inline="inlineform"
            @ok="onSubmit"
          />
      </OAccordionCard>
    </OAccordion>-->
  </div>
</template>

<script>
import OTable from '@dnvgl-onefoundation/onedesign-vue/src/components/Table'
import OrganisationService from '../services/OrganisationService'
import Vue from 'vue'

export default Vue.extend({
  components: {
    OTable
  },
  data() {
    const tablefields = [
      {
        name: 'ID',
        heading: '#ID',
        sortable: true,
        type: 'number',
        invalidFeedback: 'Enter correct ID',
        validate: function(id) {
          return id > 0;
        }
      },
      {
        name: 'OrganisationName',
        heading: 'Organisation Name',
        sortable: true,
        type: 'input'
      },
      {
        name: 'OrganisationAccessCode',
        heading: 'Access Code',
        sortable: true,
        type: 'input'
      }
    ];

    const formfields = [
      {
        "name": "OrganisationName",
        "heading": "Enter the name of an organisation to add and generate an access code",
        "type": "input",
        "required": true,
        "invalidFeedback": "Please supply a valid name"
      }
    ]

    const defaultsort = [
      {
        direction: 'asc',
        name: 'id'
      }
    ];
    

    return {
      tablefields: tablefields,
      formfields : formfields,
      organisations: [],
      details: {
        OrganisationName : null,
      },
      defaultsort: defaultsort,
      currentPage: 1,
      perPage: 10,
      defaultText: {
        Pagination: {
          previous: 'Previous',
          next: 'Next'
        },
        TableFilterRow: {
          clearSelection: 'Clear selection',
          clearAll: 'Clear All Filters'
        },
        TableOptionsDropDown: {
          tableSize: 'Table size',
          small: 'Small',
          large: 'Large'
        },
        TableColumnsDropDown: {
          visibleColumns: 'Visible columns',
          selectColumns: 'Select columns to display in the table',
          selectedItems: 'Selected'
        }
      },
      okButtonText: "Submit",
      readonly: false,
      inlineform: false,
    };
  },
  async mounted () {
    this.organisations = (await OrganisationService.getAllOrganisation()).data
    console.log('organisations', this.organisations)
  },
  computed: {
    pages() {
      return {
        total: this.organisations.length,
        per_page: this.perPage,
        page: this.currentPage
      };
    }
  },
  methods: {
    pageChanged(page) {
      if (page) {
        this.currentPage = page;
      }
    },
    deleteFilter(filter) {
      const index = this.filters.indexOf(filter);
      this.filters.splice(index, 1);
    },
    deleteSort(sort) {
      const index = this.sort.indexOf(sort);
      this.sort.splice(index, 1);
    },
    toggleModal() {
      this.showWizard = !this.showWizard;
    },
    toggleExpandCollapseSwapped() {
      this.expandCollapseSwapped = !this.expandCollapseSwapped;
    },
    setExpandCollapse(column) {
      this.expandCollapseFullWidth = column;
    },
    async onSubmit(){
      try{
        console.log(this.details)
        await OrganisationService.addNewOrganisation(this.details)
        //alert("The new organisation :" + this.details.OrganisationName+ " is now added to the MyISRS database.")
        this.organisations = (await OrganisationService.getAllOrganisation()).data

      } catch (err) {
        console.log(err)
      }
      
    }
  }
})
</script>

<style scoped>
.tab-content:focus {
  outline: none;
}
</style>
